import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

import { Layout } from "../components"

import styles from "./impressum.module.css"

export default ({ data }) => (
  <Layout>
    <div className={styles.content}>
      <ReactMarkdown source={data.allContentfulPage.edges[0].node.text.text} />
    </div>
  </Layout>
)

// Use the contentful_id since that shouldn't change

export const query = graphql`
  query {
    allContentfulPage(
      filter: { contentful_id: { eq: "4Y42YG6UHx6qbWolY9Mpva" } }
    ) {
      edges {
        node {
          title
          text {
            text
          }
        }
      }
    }
  }
`
